module controllers {
    export module applicationmain {
        interface IConsignmentList extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        interface IConsignmentListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class consignmentListCtrl {

            static $inject = [
                "$scope",
                "$stateParams",
                "$rootScope",
                "consignmentService",
                "$timeout",
                "generalService",
                "$q",
                "$state",
                "entityService",
                "supplierService",
                "consigneeService",
                "customerService",
                "consignmentTypeService",
                "statusService",
                "ediCusresStatusService",
                "isInbound",
                "$uibModal"
            ];

            conSearch: interfaces.consignment.IConsignmentSearch = {
                ConsignmentStatusId: 0,
                SLAStatus: -1
            };

            ViewOnlyMyOrders: boolean = false;

            searchAccordian: boolean = false;

            filterDateOpen: boolean = false;

            showGrid: boolean = false;
            IsLoading: boolean = false;
            fromDateOpen: boolean = false;
            toDateOpen: boolean = false;

            IsInbound: boolean = false;
            invoiceNumber: string = "";

            //Entity Variables
            ownerEntityId: number;
            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>(),
                ConsignmentTypeList: Array<interfaces.logistics.IConsignmentType>(),
                ConsignmentStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
                EDICusresStatusList: Array<interfaces.applicationcore.IDropdownModel>()
            };

            apiConList: uiGrid.IGridApi;
            selectedCon: uiGrid.IGridRow;

            filterNames: string[] = ["OwnerEntityCode", "WaybillNumber", "WaybillDate",
                "ConsignmentTypeCode", "ManifestNumber","ConsigneeCode", "ConsigneeName","CustomerCode", "CustomerName","SupplierCode", "SupplierName",
                "CurrencyCode", "ResponsibleUser", "RouteCode", "Status", "EstimatedTimeArrival", "EstimateDateOfLoading",
                "TransportMode", "CountryOfLoading", "CountryOfUnloading", "SLA", "SLAStatus","ReferenceNumber","BuyersReference","LoadingLocation","UnloadingLocation"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            sortName: string;
            sortDirection: string;

            constructor(
                private $scope: IConsignmentList,
                private $stateParams: IConsignmentListParams,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private consignmentService: interfaces.consignment.IConsignmentService,
                private $timeout: ng.ITimeoutService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private supplierService: interfaces.master.ISupplierService,
                private consigneeService: interfaces.master.IConsigneeService,
                private customerService: interfaces.master.ICustomerService,
                private consignmentTypeService: interfaces.logistics.IConsignmentTypeService,
                private statusService: interfaces.master.IStatusService,
                private ediStatusService: interfaces.integration.IEDICusresStatusService,
                private isInbound: boolean,
                private $uibModal: ng.ui.bootstrap.IModalService,
            ) {

                this.IsInbound = isInbound;

                if ($stateParams.FilterList) {
                    this.conSearch = {};
                    this.filterList = $stateParams.FilterList;
                }

                var obj: any = {}
                if ($stateParams.SearchList) {
                    angular.forEach($stateParams.SearchList, (value, index) => {
                        obj[value.key] = value.value;
                    });
                    this.conSearch = obj;
                    this.conSearch.ConsignmentStatusId = 0;
                }                          

                this.loadControls();

                $timeout(() => {
                    this.showGrid = true;
                    this.ViewOnlyMyOrders = false;
                });
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadControls() {
                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.getCurrentEntity(),
                    this.loadEntities(),
                    this.loadConsignmentType(),
                    this.getConsignmentStatusDropdown(),
                    this.getEDICusresStatusList()
                ]

                this.$q.all(controlPromises).then((data) => {
                    this.$scope.ShowGrid = true;
                });
            }

            //Loading Entities
            loadEntities(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadConsignees(searchText: string) {
                if (this.ownerEntityId) {
                    return this.consigneeService.getDropdownList(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadCustomers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.customerService.getDropdownList(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query({
                    }, () => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadConsignmentType() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.consignmentTypeService.getDropdownList(this.ownerEntityId, this.isInbound).query({}, (successList) => {
                    this.dropdownsObject.ConsignmentTypeList = successList;
                    defered.resolve(successList);

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            getConsignmentStatusDropdown() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.statusService.getStatusDropdownList().query({
                    type: this.isInbound ? Enum.EnumStatusType.ConsignmentImport : Enum.EnumStatusType.ConsignmentExport
                }, (result) => {

                    this.dropdownsObject.ConsignmentStatusList = result;
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            getEDICusresStatusList() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.ediStatusService.getDropdownList(this.isInbound).query({}, (result) => {
                    this.dropdownsObject.EDICusresStatusList = result;
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;

            }

            getCountryLoadingList() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.consignmentService.getCountryLoadingList().query({}, (result) => {
                    defered.resolve(result);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.conSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiConList) {
                    var grid = this.apiConList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject,
                    isInbound: this.isInbound,
                    invoiceNumber: this.invoiceNumber
                }

                this.IsLoading = true;
                this.consignmentService.getConsignmentsExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwConList.data = [];
                    this.gvwConList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            search_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.conSearch = {
                    ConsignmentStatusId: 0
                }

                if (this.apiConList !== undefined) {
                    this.apiConList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiConList.grid.resetColumnSorting(null);
                        this.invoiceNumber = "";
                        this.DoSearch();
                    });
                } else {
                    this.invoiceNumber = "";
                    this.DoSearch();
                }
            }

            showAll() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                
                this.DoSearch();
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiConList.grid.getColumn(name) &&
                        (this.apiConList.grid.getColumn(name).filters[0].term || this.apiConList.grid.getColumn(name).filters[0].term == ""))
                    {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue)
                        {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiConList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else
                        {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiConList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList)
                {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiConList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiConList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection)
                    {
                        this.apiConList.grid.sortColumn(column, this.sortDirection);
                    }
                }
            }

            editConsignment(condId: number) {
                if (this.isInbound) {
                    this.$state.go("auth.ConsignmentImports.Update", { conId: condId});
                } else {
                    this.$state.go("auth.ConsignmentExports.Update", { conId: condId });
                }
            };

            gvwConList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi);

                    this.apiConList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiConList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.SLAStatus === 1, 'SLA-danger': row.entity.SLAStatus === 2 }" ui-grid-cell></div>`,
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.consignmentListCtrl.editConsignment(row.entity.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EDIStatus",
                    displayName: "EDI",
                    field: "EDIStatus",
                    enableFiltering: false,
                    cellTemplate: `
                        <div class="GridButton">
                            <center>
                                <div ng-if="row.entity.EDIStatusIndicator === 0">
                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-edit"></span>
                                    </button>
                                </div>
                                <div ng-if="row.entity.EDIStatusIndicator === 4">
                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-times" style="color: #cb2330;"></span>
                                    </button>
                                </div>                         
                                <div ng-if="row.entity.EDIStatusIndicator === 3">
                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"></span>
                                    </button>
                                </div>
                                <div ng-if="row.entity.EDIStatusIndicator === 2">
                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-refresh" ></span>
                                    </button>
                                </div>                                       
                                <div ng-if="row.entity.EDIStatusIndicator === 1">
                                    <button type="button" ng-click="grid.appScope.consignmentListCtrl.openEDIPopup(row.entity.Id)" class="btn btn-default btn-sm">
                                        <span class="fa fa-check-circle" style="color: #bcd856;"></span>
                                    </button>
                                </div>
                            </center>
                        </div>
                    `,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 45,
                }, {
                    name: "OwnerEntityCode",
                    displayName: "Entity",
                    field: "OwnerEntityCode",
                    width: 60,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "WaybillNumber",
                    displayName: "Consignment",
                    field: "WaybillNumber",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
                }, {
                    name: "WaybillDate",
                    displayName: "Consignment Date",
                    field: "WaybillDate",
                    width: 150,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="waybillDate"></gts-date>
                            </div>`,
                }, {
                    name: "ManifestNumber",
                    displayName: "Manifest",
                    field: "ManifestNumber",
                    width: 150,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                }, {
                    name: "ConsigneeCode",
                    displayName: "Consignee Code",
                    field: "ConsigneeCode",
                    width: 120,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "ConsigneeName",
                    displayName: "Consignee",
                    field: "ConsigneeName",
                    width: 250,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "BuyersReference",
                    displayName: "Buyers Reference",
                    field: "BuyersReference",
                    visible: !this.isInbound,
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },{
                    name: "SupplierCode",
                    displayName: "Supplier Code",
                    field: "SupplierCode",
                    width: 100,
                    enableSorting: true,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "SupplierName",
                    displayName: "Supplier",
                    field: "SupplierName",
                    width: 250,
                    enableSorting: true,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "CurrencyCode",
                    width: 100,
                    enableSorting: true,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "ResponsibleUser",
                    displayName: "Responsible User",
                    field: "ResponsibleUser",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "Status",
                    displayName: "Status",
                    field: "Status",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "EstimatedTimeArrival",
                    displayName: "ETA",
                    field: "EstimatedTimeArrival",
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    width: 100,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimatedTimeArrival"></gts-date>
                            </div>`,
                }, {
                    name: "EstimateDateOfLoading",
                    displayName: "ETD",
                    field: "EstimateDateOfLoading",
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                    width: 100,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                            </div>`,
                }, {
                    name: "SLA",
                    displayName: "SLA",
                    field: "SLA",
                    width: 125,
                    cellFilter: 'momentDateFilter:"YYYY/MM/DD HH:mm:SS"',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLA"></gts-date>
                    </div>`,
                }, {
                    name: "ReferenceNumber",
                    displayName: "Reference Number",
                    field: "ReferenceNumber",
                    width: 150,
                    visible: this.isInbound,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: "TransportMode",
                    displayName: "Mode",
                    field: "TransportMode",
                    width: 80,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "TransportDetails",
                    displayName: "Transport Details",
                    field: "TransportDetails",
                    width: 150,
                    enableFiltering: false,
                    enableSorting: false
                }, {
                    name: "TransportNumber",
                    displayName: "Transport Number",
                    field: "TransportNumber",
                    width: 150,
                    enableFiltering: false,
                    enableSorting: false,
                }, {
                    name: "CountryOfLoading",
                    displayName: "Origin",
                    field: "CountryOfLoading",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "LoadingLocation",
                    displayName: "Origin Location",
                    field: "LoadingLocation",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "CountryOfUnloading",
                    displayName: "Destination",
                    field: "CountryOfUnloading",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "UnloadingLocation",
                    displayName: "Destination Location",
                    field: "UnloadingLocation",
                    width: 140,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "RouteCode",
                    displayName: " Route Code",
                    field: "RouteCode",
                    visible: !this.isInbound,
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },{
                    name: "CustomerCode",
                    displayName: "Customer Code",
                    field: "CustomerCode",
                    width: 120,
                    enableSorting: true,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                }, {
                    name: "CustomerName",
                    displayName: "Customer",
                    field: "CustomerName",
                    width: 150,
                    enableSorting: true,
                    visible: !this.isInbound,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                },
                {
                    name: "ConsignmentTypeCode",
                    displayName: "Consignment Type",
                    field: "ConsignmentTypeCode",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                } ]
            };

            openEDIPopup(conId: number) {
                //Open Popup.
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Consignment EDI Status</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="vertical-align">
                                        <div class="col-md-12">
                                            <div id="gridTest" ui-grid="gvwConsignmentCustomsSummary" ui-grid-resize-columns ui-grid-cellNav class="gridSmallScreen"
                                                    ng-if="gvwConsignmentCustomsSummary">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, consignmentService: interfaces.consignment.IConsignmentService) => {

                        $scope.ok = () => {
                            $uibModalInstance.dismiss(false);
                        };

                        $scope.gvwConsignmentCustomsSummary = {
                            data: [],
                            enableFiltering: false,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                consignmentService.GetConsignmentCustomsSummary(conId).query((result: interfaces.consignment.IConsignmentCustomsSummary) => {
                                    $scope.gvwConsignmentCustomsSummary.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [{
                                name: "DeclarationCountry",
                                displayName: "Declaration Country",
                                field: "DeclarationCountry",
                                type: "string",
                                width: 135,
                            }, {
                                name: "CPC",
                                displayName: "CPC",
                                field: "CPC",
                                type: "string",
                                width: 75,
                            }, {
                                name: "MRN",
                                displayName: "MRN Number",
                                field: "MRN",
                                type: "string",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                width: 145,
                            }, {
                                name: "CustomsStatus",
                                displayName: "Customs Status",
                                field: "CustomsStatus",
                                type: "string",
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                width: 132,
                            }, {
                                name: "CustomsStatusDate",
                                displayName: "Customs Status Date",
                                field: "CustomsStatusDate",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                                width: 147,
                            }, {
                                name: "CustomsMessage",
                                displayName: "Customs Message",
                                field: "CustomsMessage",
                                type: "string",
                                width: 135,
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                            }, {
                                name: "Version",
                                displayName: "Version",
                                field: "Version",
                                width: 65,
                            }, {
                                name: "Function",
                                displayName: "Function",
                                    field: "Function",
                                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                                type: "string",
                                width: 75,
                            }, {
                                name: "AssessmentDate",
                                displayName: "Assessment Date",
                                field: "AssessmentDate",
                                type: "string",
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD HH:mm:ss"}}</div>`,
                                width: 135,
                            },]
                        };
                    },
                    size: "xlg",
                    resolve: {
                    }
                }).result;
            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.showAll();
                this.apiConList = gridApi;

                this.apiConList.core.on.filterChanged(this.$scope, () => { this.ConListFilterChange(gridApi) });
                this.apiConList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ConListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiConList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ConListPageChange(newPage, pageSize) });

                this.apiConList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.$timeout(() => {
                        this.selectedCon = selected;
                    });
                });

                this.$timeout(() => {
                    if (this.selectedCon)
                        this.apiConList.selection.selectRow(this.selectedCon.entity);
                });
            }

            ConListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ConListShortChange(gridApi) {
                this.DoSearch();
            }

            ConListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });
                
                this.DoSearch();
            }   

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.conSearch, (n, key) => {
                    if(n != null || n != undefined) {
                        if (n["Id"]) {
                            searchObject.filters.push({ Name: key, Value: n.Id });
                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiConList) {
                    var grid = this.apiConList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                    isInbound: this.isInbound,
                    invoiceNumber: this.invoiceNumber,
                    viewOnlyMyOrders: this.ViewOnlyMyOrders
                }

                this.IsLoading = true;
                this.consignmentService.getConsignmentList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwConList.data = data;

                    if (data[0]) {
                        this.gvwConList.totalItems = data[0].NumRecords;
                    } else {
                        this.gvwConList.totalItems = 0;
                    }

                    this.$timeout(() => {
                        if (this.selectedCon) {
                            var index = _.findIndex(this.gvwConList.data, (o) => { return o.Id === this.selectedCon.entity.Id });
                            this.apiConList.selection.selectRow(this.gvwConList.data[index]);
                        }
                        this.getFilters();
                        this.setFilters();
                    });

                }, (data) => {
                    this.IsLoading = false;
                    this.gvwConList.data = [];
                    this.gvwConList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            create() {
                if (this.isInbound) {
                    this.$state.go("auth.ConsignmentImports.Create");
                }
                else {
                    this.$state.go("auth.ConsignmentExports.Create");
                }
            }

            createFrom() {
                if (this.isInbound) {
                    this.$state.go("auth.ConsignmentImports.CreateImportExisting");
                }
                else {
                    this.$state.go("auth.ConsignmentExports.CreateExportExisting");
                }
            }

            createFromPurchaseOrder() {
                this.$state.go("auth.ConsignmentImports.PurchaseOrder");
            }

            createFromSalesOrder() {
                this.$state.go("auth.ConsignmentExports.CreateSalesOrder");
            }

            printDocuments() {
                if (this.apiConList && this.apiConList.selection.getSelectedRows().length > 0) {

                    var selected = this.apiConList.selection.getSelectedRows()[0];

                    if (this.isInbound) {
                        this.$state.go("auth.ConsignmentImports.Update.Documents", { conId: selected.Id });
                    }
                    else {
                        this.$state.go("auth.ConsignmentExports.Update.Documents", { conId: selected.Id });
                    }

                } else {
                    this.generalService.displayMessage("Please select a consignment", Enum.EnumMessageType.Warning);
                }
            }

            close() {
                this.$state.go("^");
            }
            
        };

        angular.module("app").controller("consignmentListCtrl", controllers.applicationmain.consignmentListCtrl);
    }
}